<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else-if="is_data_ready">
        <!--  Content Start -->
        <section class="sabc_tier_sports_sec">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="sabc_tier_news_tabs">
                            <div class="sabc_tier_news_tabs">
                                <h1>Olympics</h1>
                            </div>
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'sport_home', params: { sport: 'olympics', news: 'news'}}">
                                        <span>Olympics News</span>
                                    </router-link>
                                </li> 
                                <li>
                                    <a href="" aria-current="page" class="router-link-exact-active active selected"><span>Medals Table</span></a>
                                </li>
                            </ul>
                        </div>

                        <h1 v-if="olympicsTable" style="font-style: normal;font-weight: 600;font-size: 18px;line-height: 22px;color: #0C2251;margin-bottom: 17px;display: flex;align-items: center;align-content: center;flex-wrap: wrap;gap: 10px;"><img src="/public/images/olympic.png" alt="olympic logo" style="padding-right: 0px;width: 80px;height: auto;object-fit: cover;flex-shrink: 0;"> {{olympicsTable.headline}}</h1>
                        <div v-if="olympicsTable" style="padding-bottom:8px; color:#7a7978;font-size: 13px;"> Date as at {{showCurrent()}}</div>
                        <span v-if="olympicsTable" v-html="olympicsTable.tableHTML"></span>
                    </div> 
                </div>
            </div>
        </section>
        <!--  Content end -->
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "olympics_table",
        data() {
            return {
                olympicsTable: '',
                is_data_ready: true
            }
        },
        components: {
        },
        mounted() {
            this.getOlympicsTable();
        },
        serverPrefetch() {
            return this.getOlympicsTable();
        },
        metaInfo() {

           if (this.$store.state.siteSetting) {
                let keywords = 'Olympics, Olympics Paris, Olympic Games Paris 2024';
                let title = 'Latest Olympics Table and Latest Trends - SABC Sport';
                let description = 'Olympics table and stats';
                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },
        methods: {
            getOlympicsTable(offset) {
                this.url = this.$GetOlympicsTable;
                return this.$axios.get(this.url)
                        .then(response => {
                            if (response.data) {
                                this.olympicsTable = response.data.olympicsTable;
                                this.is_data_ready = true;
                            }
                        })
                        .catch(error => {
                        })
            },
            showCurrent(){
                return moment().format("D MMM YYYY");
            }

        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/featured.scss';
@import '~@/assets/scss/tier_sports.scss';
@import '~@/assets/scss/right_sidebar.scss';
</style>
